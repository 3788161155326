
export default [{
    path: '/userCenter',
    component: () =>
        import('@/views/UserCenter/Index.vue'),
    name: 'userCenter',
    meta: {
        title: "个人中心 - 专注一线大厂算法面试 - CSON",
        requireAuth: true
    },
},
{
    path: '/userCenter/order',
    component: () =>
        import('@/views/UserCenter/OrderList.vue'),
    name: 'OrderList',
    meta: {
        title: "我的订单 - 专注一线大厂算法面试 - CSON",
        requireAuth: true
    },
},
{
    path: '/userCenter/course',
    component: () =>
        import('@/views/UserCenter/CourseList.vue'),
    name: 'CourseList',
    meta: {
        title: "我的课程 - 专注一线大厂算法面试 - CSON",
        requireAuth: true
    },
}
]