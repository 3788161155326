// 课程
export default [{
        path: '/course/2', //LeetCode题目视频讲解
        component: () =>
            import ('@/views/Courses/Class2.vue'),
        name: 'Class2',
        meta: {
            title: "LeetCode题目视频讲解 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    },
    {
        path: '/course/3', //北美SDE算法速成班
        component: () =>
            import ('@/views/Courses/Class3.vue'),
        name: 'Class3',
        meta: {
            title: "北美SDE算法速成班 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    },
    {
        path: '/course/4', //求职面试算法班
        component: () =>
            import ('@/views/Courses/Class4.vue'),
        name: 'Class4',
        meta: {
            title: "求职面试算法班 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    },
    {
        path: '/course/5', //求职面试刷题班
        component: () =>
            import ('@/views/Courses/Class5.vue'),
        name: 'Class5',
        meta: {
            title: "求职面试刷题班 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    },
    {
        path: '/course/6', //北美SDE求职速成班
        component: () =>
            import ('@/views/Courses/Class6.vue'),
        name: 'Class6',
        meta: {
            title: "北美SDE求职速成班 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    },
    {
        path: '/course/7', //北美SDE求职旗舰版
        component: () =>
            import ('@/views/Courses/Class7.vue'),
        name: 'Class7',
        meta: {
            title: "北美SDE求职旗舰班 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    }, {
        path: '/course/8', //求职面试刷题班
        component: () =>
            import ('@/views/Courses/Class8.vue'),
        name: 'Class8',
        meta: {
            title: "求职面试刷题班 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    },{
        path: '/course/11', //北美CS求职课程
        component: () =>
            import ('@/views/Courses/Class11.vue'),
        name: 'Class11',
        meta: {
            title: "北美CS求职课程 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    },{
        path: '/course/12', //北美SDE速成班
        component: () =>
            import ('@/views/Courses/Class12.vue'),
        name: 'Class12',
        meta: {
            title: "北美SDE速成班 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    },{
        path: '/course/13', //北美SDE速成班
        component: () =>
            import ('@/views/Courses/Class13.vue'),
        name: 'Class13',
        meta: {
            title: "北美SDE速成班 - 专注一线大厂算法面试 - CSON"
        },
        children: []
    }
]