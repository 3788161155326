// 模拟面试
export default [{
    path: '/pay/confirm',
    component: () =>
        import('@/views/Pay/Confirm.vue'),
    name: 'confirm',
    meta: {
        title: "确认订单 - 专注一线大厂算法面试 - CSON",
        requireAuth: true
    },
},
{
    path: '/pay/order',
    component: () =>
        import('@/views/Pay/Order.vue'),
    name: 'Order',
    meta: {
        title: "选择付款方式 - 专注一线大厂算法面试 - CSON",
        requireAuth: true
    },
},
    {
        path: '/payform',
        component: () =>
            import('@/views/Pay/payForm.vue'),
        name: 'Order',
        meta: {
            title: "选择付款方式 - 专注一线大厂算法面试 - CSON",
            requireAuth: true
        },
    },
{
    path: '/pay/success',
    component: () =>
        import('@/views/Pay/PaySuccess.vue'),
    name: 'PaySuccess',
    meta: {
        title: "支付成功 - 专注一线大厂算法面试 - CSON",
        requireAuth: true
    },
},
{
    path: '/pay/failure',
    component: () =>
        import('@/views/Pay/PayFailure.vue'),
    name: 'PayFailure',
    meta: {
        title: "支付失败 - 专注一线大厂算法面试 - CSON",
        requireAuth: true
    },
}
]