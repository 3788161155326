import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueQuillEditor from 'vue-quill-editor'
import FunctionalCalendar from 'vue-functional-calendar';


import './assets/font/iconfont.css'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import Sticky from 'vue-sticky-directive'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import scroll from 'vue-seamless-scroll'
import moment from 'moment'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import axios from "axios";

import VueAwesomeSwiper from 'vue-awesome-swiper';
import "swiper/dist/css/swiper.css";
import Highlight from './utils/highlight';
Vue.use(VueAwesomeSwiper);

Vue.use(scroll);
Vue.use(Highlight);
Vue.use(dataV);
Vue.use(ElementUI);
Vue.use(VideoPlayer);
Vue.use(Sticky);
Vue.use(VueQuillEditor);
Vue.use(FunctionalCalendar, {
    dayNames: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    monthNames: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
});

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.prototype.$moment = moment;
var EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
    $bus: {
        get: function() {
            return EventBus
        }
    }
})
axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
//百度统计
var _hmt = _hmt || [];
window._hmt = _hmt; // 将_hmt挂载到window下
(function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?bb973c8481a48f63fc6781cff2a8cb19";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();
router.beforeEach((to, from, next) => {
    if (_hmt) {
        if (to.path) {
            _hmt.push(['_trackPageview', '/#' + to.fullPath]);
        }
    }
    next();
});