<template>
  <div class="header">
    <router-link to="/">
      <img class="logo" src="@/assets/img/layout/white-logo.png" />
    </router-link>
    <img
      class="menuIcon"
      src="@/assets/img/layout/menuIcon.png"
      @click="showDrawer = true"
    />
    <el-drawer
      :visible.sync="showDrawer"
      direction="rtl"
      append-to-body
      :show-close="false"
      :withHeader="false"
      width="80%"
    >
      <div class="menuContainer">
        <router-link to="/">
          <img
            class="menuLogo"
            src="@/assets/img/layout/blue-logo.png"
            alt=""
          />
        </router-link>
        <div v-if="userInfo && userInfo.id" style="margin-bottom: 26px">
          <router-link to="/userCenter">
            <img
              :src="userInfo.avatar"
              alt=""
              class="userAvatar"
              @click="showDrawer = false"
            />
          </router-link>
        </div>
        <div class="routerLists">
          <div
            v-for="(router, index) in routerLists"
            :key="index"
            class="routes"
          >
            <div
              @click="jumpRoutes(router)"
              v-if="!router.tip"
              :class="['routeTitle', path == router.path ? 'active' : '']"
            >
              {{ router.name }}
            </div>
            <el-badge v-else :value="router.tip">
              <div
                @click="jumpRoutes(router)"
                :class="['routeTitle', path == router.path ? 'active' : '']"
              >
                {{ router.name }}
              </div>
            </el-badge>
          </div>
        </div>
        <router-link to="/loginWeixin" v-if="!userInfo || !userInfo.id">
          <el-button class="login" @click="showDrawer = false"
            >注册/登录</el-button
          >
        </router-link>

        <el-button
          class="resign"
          v-if="userInfo && userInfo.id"
          @click="resign"
        >
          退出登录
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      showDrawer: false,
      routerLists: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "全部课程",
          path: "/course",
        },
        {
          path: "/studyAbroadApply",
          name: "北美留学",
          checked: false,
        },
        {
          path: "/activity-ask",
          name: "免费选校",
          checked: false,
        },
        {
          name: "LC分类顺序表",
          path: "/leetcodeClassification",
        },
        // {
        //   name: "面经直播",
        //   path: "/live",
        // },
        {
          name: "北美公司",
          path: "/companyList",
        },

        {
          name: "创始人故事",
          path: "/story",
        },
      ],
      path: "",
    };
  },
  mounted() {
    this.path = this.$route.path;
  },
  methods: {
    jumpRoutes(route) {
      this.showDrawer = false;
      this.$router.push(route.path);
    },
    resign() {
      window.localStorage.setItem("CSON_PAGE_TOKEN", "");
      window.location.href = "#/";
      window.location.reload();
    },
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1888;
  width: 100%;
  background: url(../../assets/img/layout/head-bg.jpg);
  background-size: 100% 100%;
  height: 54px;
  .logo {
    width: 100px;
    margin-left: 20px;
    transform: translateY(12px);
  }
  .menuIcon {
    width: 20px;
    float: right;
    margin-right: 20px;
    transform: translateY(18px);
  }
}
::v-deep .el-drawer {
  width: 80% !important;
}
.menuContainer {
  text-align: center;
  .menuLogo {
    width: 90px;
    margin-top: 42px;
    margin-bottom: 36px;
  }
  .routes {
    margin-bottom: 28px;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #676767;
    line-height: 14px;

    .routeTitle {
      display: inline-block;
      padding-bottom: 4px;
      &.active {
        color: #fa6400;
        border-bottom: 2px solid #fa6400;
      }
    }
  }
}
.userAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.resign,
.login {
  width: 156px;

  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  border-radius: 100px;
}
.resign {
  background: linear-gradient(
    to right,
    rgba(150, 150, 150, 1),
    rgba(215, 215, 215, 1)
  );
}
.login {
  background: linear-gradient(
    to right,
    rgba(9, 212, 144, 1),
    rgba(126, 221, 129, 1)
  );
}
::v-deep .el-badge__content.is-fixed {
  right: 0px;
}
</style>