// 防空取参
export function getIn(data, array, initial = null) {
    let obj = Object.assign({}, data)
    for (let i = 0; i < array.length; i++) {
        if (typeof obj !== 'object' || obj === null) {
            return initial
        }
        const prop = array[i]
        obj = obj[prop]
    }
    if (obj === undefined || obj === null) {
        return initial
    }
    return obj
}

export function setStorageForArray(key, array) {
    if (array && array.length > 0) {
        let temp = [];
        array.forEach(item => temp.push(JSON.stringify(item)));
        let arrStr = encodeURIComponent(temp.join("*"));
        window.localStorage.setItem(key, arrStr);
    }
}

export function getStorageForArray(key) {
    let storage = window.localStorage.getItem(key);
    if (storage) {
        storage = decodeURIComponent(storage);
        storage = storage.split("*");
    }
    return storage || [];
}

export function dateFormat(thisDate, fmt) {
    var o = {
        "M+": thisDate.getMonth() + 1,
        "d+": thisDate.getDate(),
        "h+": thisDate.getHours(),
        "m+": thisDate.getMinutes(),
        "s+": thisDate.getSeconds(),
        "q+": Math.floor((thisDate.getMonth() + 3) / 3),
        S: thisDate.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(
            RegExp.$1,
            (thisDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return fmt;
}
export function navigateBack(lv = -1, delay = 0) {
    const timeout = setTimeout(() => {
        window.history.go(lv);
        clearTimeout(timeout);
    }, delay)
}