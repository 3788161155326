import axios from 'axios';
import {getIn} from './index';

const IGNORE_URLS = [];

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000, // request timeout
    withCredentials: false
})

service.interceptors.request.use(
    async config => {
        const {url = ''} = config;
        const token = window.localStorage.getItem('CSON_PAGE_TOKEN');
        if (!token && !IGNORE_URLS.includes(url)) {
            console.log('[未登录-需要重定向到登录页]');
            // window.location.href = '.#/login';
        } else {
            config.headers['X-Access-Token'] = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

service.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        // console.log('[接口请求失败]', error.response);
        console.log(error)
        return Promise.resolve(getIn(error, ['response', 'data']));
    }
)

export default service
