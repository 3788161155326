import Vue from 'vue'
import VueRouter from 'vue-router'
import course from "./course"
import leetcodeClassification from "./leetcodeClassification"
import mock from "./mock"
import pay from "./pay"
import userCenter from "./userCenter"
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    meta: {
        title: "CSON - 专注一线大厂算法面试"
    },
    component: () =>
        import('@/views/Index.vue')
},
{
    path: '/bindEmail',
    name: 'bindEmail',
    meta: {
        title: "完善个人信息 - 专注一线大厂算法面试",
        requireAuth: true
    },
    component: () =>
        import('@/views/BindEmail.vue')
},
{
    path: '/course',
    name: 'course',
    meta: {
        title: "求职面试全家桶 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Course.vue')
},
{
    path: '/companyList',
    name: 'companyList',
    meta: {
        title: "北美公司 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Companies.vue')
},
{
    path: '/refer',
    name: 'refer',
    meta: {
        title: "北美内推 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Refer.vue')
}, {
    path: '/story',
    name: 'story',
    meta: {
        title: "创始人故事 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Story.vue')
},
{
    path: '/leetcode',
    name: 'leetcode',
    meta: {
        title: "LC分类顺序表 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/LeetCode.vue')
},
{
    path: '/login',
    name: 'login',
    meta: {
        title: "登录 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/_login/loginCode.vue')
},
{
    path: '/loginPassword',
    name: 'login',
    meta: {
        title: "登录 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/_login/loginPassword.vue')
},
{
    path: '/userAgreement',
    name: 'userAgreement',
    meta: {
        title: "用户协议 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/UserAgreement.vue')
},
{
    path: '/subscription',
    name: 'subscription',
    meta: {
        title: "订阅 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Courses/LeetCodeSubscribe.vue')
},
{
    path: '/activity',
    name: 'activity',
    meta: {
        title: "免费课程 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Activity.vue')
},
{
    path: '/research',
    name: 'research',
    meta: {
        title: "问卷调查 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Research.vue')
},
{
    path: '/live',
    name: 'live',
    meta: {
        title: "面经直播 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Live/Index.vue')
},
{
    path: '/liveRoom',
    name: 'liveRoom',
    meta: {
        title: "直播间 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Live/LiveRoom.vue')
},
{
    path: '/livePage',
    name: 'livePage',
    component: () =>
        import('@/views/Live/LivePage.vue'),
    meta: {
        title: "北美直播 - 专注北美算法面试 - CSON"
    }
},
{
    path: '/interviewDetail',
    name: 'interviewDetail',
    meta: {
        title: "面经详情 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Live/InterviewDetail.vue')
},
{
    path: '/questionDetail',
    name: 'questionDetail',
    meta: {
        title: "面经题详情 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/Live/QuestionDetail.vue')
},
{
    path: '/hyClass',
    name: 'hyClass',
    meta: {
        title: ""
    },
    component: () =>
        import('@/views/Courses/hyClass.vue')
},
{
    path: '/studyAbroadApply', //北美CS留学申请
    component: () =>
        import('@/views/Courses/0-liuxue.vue'),
    name: 'apply',
    meta: {
        title: "北美CS留学申请  - 专注一线大厂算法面试 -CSON"
    },
    children: []
},
{
    path: '/activity-ask',
    name: 'activity-ask',
    component: () =>
        import('@/views/activity-ask.vue')
},

{
    path: '/loginWeixin',
    name: 'loginWeixin',
    meta: {
        title: "登录 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/_login/loginWeixin.vue')
},
{
    path: '/loginCode',
    name: 'loginCode',
    meta: {
        title: "登录 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/_login/loginCode.vue')
}, {
    path: '/loginPassword',
    name: 'loginPassword',
    meta: {
        title: "登录 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/_login/loginPassword.vue')
}, {
    path: '/profile',
    name: 'profile',
    meta: {
        title: "登录 - 专注一线大厂算法面试 - CSON"
    },
    component: () =>
        import('@/views/_login/profile.vue')
},
...course,
...leetcodeClassification,
...mock,
...pay,
...userCenter
]

const router = new VueRouter({
    mode: 'hash',
    routes
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    const token = window.localStorage.getItem('CSON_PAGE_TOKEN');
    if (to.meta.requireAuth) {
        if (token) {
            next();
        } else {
            next({
                path: "/login" //指向为你的登录界面
            });
        }
    } else {
        next();
    }

    if (to.fullPath === "/login") {
        if (token) {
            next({
                path: from.fullPath
            });
        } else {
            next();
        }
    }
    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
})
export default router