import request from "@/utils/request";

// 获取用户信息
export async function queryUserInfo() {
    try {
        return await request({
            url: `/user/info`,
            method: "get",
            data: {},
        });
    } catch (error) {
        return {};
    }
}

// 用户协议
export async function getUserAgreement() {
    try {
        return await request({
            url: `/faq?type=7&objectId=&keyword=`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}


// 获取优惠打折价格
export async function getCouponDiscountPrice(data) {
    try {
        return await request({
            url: `/order/getPromotionCodeAmount`,
            method: "post",
            data
        });
    } catch (error) {
        return {};
    }
}

/**
 * 创建订单
 */
export async function createOrder(data) {
    try {
        return await request({
            url: `/order/create`,
            method: "post",
            data,
        });
    } catch (error) {
        return {};
    }
}

/**
 * 获取订单
 */
export async function getOrder(orderId) {
    try {
        return await request({
            url: `/order/${orderId}`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}

/**
 * 订单支付
 * @param payType
 * @param orderId
 */
export function payOrder(payType, orderId) {
    try {
        let result = request({
            url: `/order/payment/${payType}/${orderId}?mobile=true`,
            method: "post",
            sync: true,
        });
        console.log("----------123123123", result);
        return result;
    } catch (error) {
        return {};
    }
}


export async function paymentCheck(orderId) {
    try {
        return await request({
            url: `/order/isSuccess/${orderId}`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}
export async function getCourseBanner(id) {
    try {
        return await request({
            url: `/pictureOfLocation/pictureOfPosition?location=${id}`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}
