import {queryUserInfo} from "../service/index";
import {getIn} from "../utils/index";

export default {
    async queryUserInfo(context) {
        const result = await queryUserInfo();

        console.log(result);

        // 登录时间过久清除缓存退出登录
        if (result.code == 500) {

            window.localStorage.setItem("CSON_PAGE_TOKEN", "");
            window.location.href = "#/";
            window.location.reload();

            return;
        }
        const userInfo = getIn(result, ["result"]) || {};
        context.commit('UPDATE_USERINFO', userInfo)
    }
}
