// 模拟面试
export default [{
        path: '/mock/1',
        component: () =>
            import ('@/views/Mocks/BehaviorInterview.vue'),
        name: 'BehaviorInterview',
        meta: {
            title: "1v1 BQ模拟面试 - 专注一线大厂算法面试 - CSON"
        },
    }, {
        path: '/mock/2',
        component: () =>
            import ('@/views/Mocks/MockInterview.vue'),
        name: 'MockInterview',
        meta: {
            title: "1v1 模拟面试 - 专注一线大厂算法面试 - CSON"
        },
    },
    {
        path: '/mock/3',
        component: () =>
            import ('@/views/Mocks/ResumeInterview.vue'),
        name: 'BehaviorInterview',
        meta: {
            title: "1v1 深挖修改简历 - 专注一线大厂算法面试 - CSON"
        },
    },
    {
        path: '/mock/4',
        component: () =>
            import ('@/views/Mocks/JobPlanning.vue'),
        name: 'JobPlanning',
        meta: {
            title: "北美求职规划 - 专注一线大厂算法面试 - CSON"
        },
    },
    {
        path: '/mock/5',
        component: () =>
            import ('@/views/Mocks/AlgorithmGuidance.vue'),
        name: 'AlgorithmGuidance',
        meta: {
            title: "算法刷题面试指导 - 专注一线大厂算法面试 - CSON"
        },
    },{
        path: '/mock/15',
        component: () =>
            import ('@/views/Mocks/consult.vue'),
        name: 'consult',
        meta: {
            title: "1v1咨询网页 - 专注一线大厂算法面试 - CSON"
        },
    },
]