<template>
  <div id="app">
    <Header v-if="!noLayout() && !setHeader()"></Header>
    <!-- 路由跳转转场效果 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <Footer v-if="!noLayout() && !setFooter()"></Footer>
  </div>
</template>

<script>
import Header from "@/components/layout/Header";
import Footer from "@/components/layout/Footer";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      path: "",
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.path = this.$route.path;
      const token = window.localStorage.getItem("CSON_PAGE_TOKEN");
      if (token && !this.setHeader()) {
        this.$store.dispatch("queryUserInfo");
      }
    }, 200);
    var dev = "";
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      //设备为移动端
      dev = "mobile";
    } else {
      //设备为pc
      dev = "pc";
    }
    if (dev == "pc") {
      window.location.href = "https://www.cspiration.com/#/";
    }
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
      window.scrollTo(0, 0);
    },
  },
  methods: {
    noLayout() {
      const noLayoutList = ["/research"];
      return noLayoutList.includes(this.path);
    },
    setHeader() {
      const noHeaderList = [];
      return noHeaderList.includes(this.path);
    },
    setFooter() {
      const noFooterList = [
        "/companyList",
        "/interviewDetail",
        "/questionDetail",
        "/leetcodeClassification/codeDetail",
        "/leetcodeClassification/discussion",
        "/liveRoom",
        "/pay/confirm",
        "/pay/order",
        "/pay/success",
        "/pay/failure",
        "/userCenter",
        "/userCenter/course",
        "/userCenter/order",
        "/bindEmail",
      ];

      return noFooterList.includes(this.path);
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>
