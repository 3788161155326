export default [{
        path: '/leetcodeClassification',
        component: () =>
            import ('@/views/LeetcodeClassification/Index.vue'),
        name: 'Index',
        meta: {
            title: "LeetCode分类顺序表 - 专注一线大厂算法面试 - CSON"
        },
    },
    {
        path: '/leetcodeClassification/codeDetail',
        component: () =>
            import ('@/views/LeetcodeClassification/CodeDetail.vue'),
        name: 'CodeDetail',
        meta: {
            title: "题目详情 - 专注一线大厂算法面试 - CSON"
        }
    }, {
        path: '/leetcodeClassification/discussion',
        component: () =>
            import ('@/views/LeetcodeClassification/Discussion.vue'),
        name: 'Discussion',
        meta: {
            title: "讨论区 - 专注一线大厂算法面试 - CSON"
        },
    }

]