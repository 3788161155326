<template>
  <div>
	  <div class="footer">
	    <img src="@/assets/img/layout/footer.png" />
	  </div>
	  	  <leet-dialog></leet-dialog>
  </div>
  
</template>
<script>
import leetDialog from "@/components/leetDialog.vue";
export default {
  name: "Footer",
  components: {
    leetDialog,
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.footer {
  img {
    width: 100%;
    vertical-align: top;
  }
}
</style>